import React from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
const userLang = navigator.language || navigator.userLanguage

const IntroPageButton = () => (
  <div>
    <Link to='/merchantsLog'>
      <Button
        color='orange'
        circular
        size='big'
        style={{
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 50,
          paddingRight: 50,
          backgroundColor: '#e4bf8f',
          color: '#493d2b'
        }}
      >
        {userLang === 'zh-CN' ? '开始上架' : 'Begin Listing'}
      </Button>
    </Link>
  </div>
)

export default IntroPageButton
