import React from 'react'
import {
  Grid,
  Form,
  Button,
  Segment,
  Message,
  Header,
  Icon
} from 'semantic-ui-react'

// import IntlTelInput from 'react-intl-tel-input-ueat'

import axios from 'axios'
import { withRouter } from 'react-router-dom'
// import Nav from '../nav/Nav'
import Foot from '../foot/Foot'
import newOTP from 'otp-generators'
import emailjs from 'emailjs-com'
import { passwordStrength } from 'check-password-strength'
var jwt = require('jsonwebtoken')

class SignupPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password: '',
      passStrength: '',
      passWeak: false,
      userinfo: '',
      sentEmail: '',
      loading: false,
      activeItem: 'Phone',
      somethingWrong: false
    }
    this.onHandleUsername = this.onHandleUsername.bind(this)
    this.onHandleEmail = this.onHandleEmail.bind(this)
    this.onHandlePassword = this.onHandlePassword.bind(this)
    this.onHandleSubmit = this.onHandleSubmit.bind(this)
    this.onHandlePhoneNumber = this.onHandlePhoneNumber.bind(this)
    this.onHandlePhoneVerification = this.onHandlePhoneVerification.bind(this)
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  onHandlePhoneNumber (event) {
    this.setState({
      phoneNumber: event.target.value
    })
  }

  onHandlePhoneVerification (event) {
    this.setState({
      phoneVerification: event.target.value
    })
  }

  onHandleUsername (event) {
    this.setState({
      username: event.target.value
    })

    console.log(this.state)
  }

  onHandleEmail (event) {
    this.setState({
      email: event.target.value
    })

    console.log(this.state)
  }

  onHandlePassword (event) {
    const password = event.target.value
    this.setState({
      password: password,
      passStrength: passwordStrength(password).value
    })
    console.log(this.state.passStrength)
  }

  onHandleSubmit (event) {
    event.preventDefault()
    const self = this
    const passStrength = self.state.passStrength
    const identifier = this.state.username
    const email = self.state.email
    console.log(passStrength)
    if (passStrength === 'Weak' || passStrength === 'Too weak') {
      this.setState({
        passWeak: true
      })
    } else {
      this.setState({
        passWeak: false,
        loading: true
      })
      axios
        .post('https://sheyou-backend.herokuapp.com/auth/local/register', {
          username: this.state.username,
          email: this.state.email,
          password: this.state.password,
          confirmed: false
        })
        .then(function (response) {
          console.log(response)
          self.setState({
            loading: false,
            sentEmail: true
          })
          const id = response.data.user.id
          localStorage.setItem('id', id)

          const verificationCode = newOTP.generate(6, {
            alphabets: false,
            upperCase: false,
            specialChar: false
          })

          const token = jwt.sign(
            {
              data: verificationCode
            },
            'sheyoufashion',
            { expiresIn: '5min' }
          )


          emailjs
            .send(
              'service_orld5qb',
              'template_c1h9qsr',
              {
                email: email,
                reply_to: 'susanna.xinshu@gmail.com',
                name: identifier,
                verificationCode: verificationCode
              },
              'user_4rMSnDYBigUutDlR5RSib'
            )
            .then(
              function (response) {
                console.log('SUCCESS!', response.status, response.text)
                self.setState({
                  loading: false,
                  sentEmail: true
                })

                localStorage.setItem('identifier', identifier)
                localStorage.setItem('verificationCode', token)
                self.props.history.push('/emailConfirmation')
              },
              function (error) {
                self.setState({
                  loading: false,
                  sentEmail: false,
                  somethingWrong: 'FAILED...' + error.text
                })
              }
            )
        })
        .catch(error => {
          const email = self.state.email

          console.log(error)
          self.setState({
            loading: false,
            sentEmail: false,
            somethingWrong: error.response.data.message[0].messages[0].message
          })
          localStorage.setItem('email', email)

          console.log('An error occurred:', error.response)
        })
    }
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const sentEmail = this.state.sentEmail
    const passWeak = this.state.passWeak
    const loading = this.state.loading
    const somethingWrong = this.state.somethingWrong
    return (
      <div>
        <Grid className='gradient_limeade'>
          <Grid.Row style={{ paddingTop: 100, paddingBottom: 100 }} centered>
            <Grid.Column width={10}>

              <Segment>
              <Grid.Row>
              
              <Grid.Column width={16}>
                <Header as='h2' color='teal' textAlign='center'>
                  Sign Up
                </Header>
              </Grid.Column>
            </Grid.Row>
                <Form stackable>
                  <Form.Input
                    onChange={this.onHandleUsername}
                    label='Username'
                    placeholder='Username'
                    error={this.state.username === ''&& this.state.sentEmail !== '' ? { content: 'Please enter a username', pointing: 'below' } : null}
                  />
                  <Form.Input
                    onChange={this.onHandleEmail}
                    label='Email'
                    placeholder='Email'
                    error={this.state.email === ''&& this.state.sentEmail !== '' ? { content: 'Please enter an email address', pointing: 'below' } : null}
                  />
                  <Form.Input
                    onChange={this.onHandlePassword}
                    label='Password'
                    placeholder='Password'
                    type='password'
                  />
                </Form>

                <br />
                {passWeak === true && (
                  <Grid.Row>
                    <Message
                      warning
                      header='Weak Password'
                      content='Please create a stronger password combination. Your password must be longer than 8 digits, including upper lowercase letters, numbers, and special characters.'
                    />
                  </Grid.Row>
                )}
                {sentEmail === true && (
                  <Grid.Row>
                    <Message
                      success
                      header='Email Sent'
                      content='We Have Successfully Sent Confirmation, Please Check In Your Email, Expire in 5 Minutes'
                    />
                  </Grid.Row>
                )}
                {(sentEmail === false || somethingWrong !== false) && (
                  <Grid.Row>
                    <Message
                      error
                      header='Register Failure'
                      content={'Something Wrong: ' + (somethingWrong === 'Email is already taken.' ? 'Email is already taken. Please go to log in' : somethingWrong)}
                    />
                  </Grid.Row>
                )}
                <br />

                {loading === true && (
                  <Header size='big'>
                    <Icon loading name='asterisk' size='huge' color='yellow' />
                    Loading...
                  </Header>
                )}

                <Grid columns={3} centered>
                  <Grid.Column width={6}>
                    <Button
                      onClick={this.onHandleSubmit}
                      type='submit'
                      fluid
                      size='large'
                      floated='right'
                      content='Sign UP'
                      color='orange'
                      disabled={this.state.username === '' || this.state.email === ''||this.state.password===''}
                    />
                  </Grid.Column>
                </Grid>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Foot />
      </div>
    )
  }
}

export default withRouter(SignupPage)
