import React, { useEffect, useRef, useState } from 'react'
import './IntroSecVertical.css'
import { Grid, Header } from 'semantic-ui-react'
import IntroPageButton from './IntroSecButton'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'


const userLang = navigator.language || navigator.userLanguage

const IntroSecAnimated = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div>
      <div
        className='intro-section'
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727123216/landing_first_page_76276a5712.svg')"
        }}
      >
        {windowWidth >= 735 && (
          <div className='intro-section'>
            <div className='full-height-section' style={{ padding: 30 }}>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={8}></Grid.Column>
                  <Grid.Column width={8}>
                    <Grid centered>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            style={{
                              fontSize: 40,
                              textAlign: 'right',
                              textShadow: '2px 2px #e4bf8f'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '给设计师一个平台'
                              : 'A Platform for Designers'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            as='h2'
                            occasion-
                            style={{
                              textAlign: 'right',
                              textShadow: '2px 2px #e4bf8f'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '按照您的价格和条件进行租赁、销售或交易。'
                              : 'Rent out, sell, or trade, at your price and terms'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <IntroPageButton />
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
      </div>

      {windowWidth < 735 && (
        <div>
          <div
            className='half-height-section'
            style={{ padding: 30, backgroundColor: '#F5F5DD' }}
          >
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    occasion-
                    style={{
                      fontSize: 40,
                      textAlign: 'center',
                      textShadow: '2px 2px #e4bf8f'
                    }}
                  >
                    {userLang === 'zh-CN'
                      ? '在本地和线上与年轻创意人才和设计师们交流个性'
                      : 'A Platform for Designers'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    as='h2'
                    occasion-
                    style={{
                      textAlign: 'CENTER',
                      textShadow: '2px 2px #e4bf8f'
                    }}
                  >
                    {userLang === 'zh-CN'
                      ? '按照您的价格和条件进行租赁、销售或交易。'
                      : 'Rent, sell, or trade, locally or online, at your price and terms'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <IntroPageButton />
              </Grid.Row>
            </Grid>
          </div>
        </div>
      )}

      <div
        className='intro-section'
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/redhead_b0b08dae63.svg')"
        }}
      >
        {windowWidth >= 735 && (
          <div className='intro-section'>
            <div className='full-height-section' style={{ padding: 30 }}>
              <Grid centered>
                <Grid.Row columns={2}>
                  <Grid.Column width={10}>
                    <Grid centered>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            as='h2'
                            textAlign='left'
                            style={{ textShadow: '2px 2px #e4bf8f' }}
                          >
                            {userLang === 'zh-CN'
                              ? '和设计师和时尚爱好者们一起庆祝个性风格'
                              : 'Celebrate individuality with fashion lovers and designers'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Header
                            style={{
                              fontSize: 40,
                              textAlign: 'left',
                              textShadow: '2px 2px #e4bf8f'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '自由化平台'
                              : 'A Platform of Freedom and Customization'}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Link to='/about'>
                          <Button
                            color='orange'
                            circular
                            size='big'
                            style={{
                              paddingTop: 20,
                              paddingBottom: 20,
                              paddingLeft: 50,
                              paddingRight: 50,
                              backgroundColor: '#e4bf8f',
                              color: '#493d2b'
                            }}
                          >
                            {userLang === 'zh-CN'
                              ? '怎么使用我们'
                              : 'How to Use Us'}
                          </Button>
                        </Link>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={6}></Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </div>
        )}
        {/* {windowWidth >= 735 && (
          <div className='full-height-section' style={{ padding: 30 }}>
            <Grid>
              <Grid.Row
                style={{
                  position: 'absolute',
                  width: '50vw',
                  top:'10vh',
                  left: '8vw'
                }}
              >
                <Header
                  occasion-
                  style={{ width: '60vw', paddingTop: 25, fontSize: 40 }}
                >
                  {userLang === 'zh-CN'
                    ? '自由化时尚租赁平台'
                    : 'A customizable next-generation fashion platform'}
                </Header>
              </Grid.Row>
              <Grid.Row
                style={{
                  position: 'absolute',
                  width: '50vw',
                  bottom: '25vh',
                  left: '8vw'
                }}
              >
                <IntroPageButton />
              </Grid.Row>
            </Grid>
          </div>
        )} */}
      </div>

      {windowWidth < 735 && (
        <div>
          <div
            className='half-height-section'
            style={{ padding: 30, backgroundColor: '#F5F5DD' }}
          >
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <Header
                    as='h2'
                    style={{ textShadow: '2px 2px #e4bf8f' }}
                    textAlign='center'
                  >
                    {userLang === 'zh-CN'
                      ? '和设计师和时尚爱好者们一起庆祝个性风格'
                      : 'Celebrate individuality with fashion lovers and designers'}
                  </Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Header
                  occasion-
                  style={{
                    fontSize: 40,
                    textAlign: 'center',
                    textShadow: '2px 2px #e4bf8f'
                  }}
                >
                  {userLang === 'zh-CN'
                    ? '自由化时尚租赁平台'
                    : 'A Platform of Freedom and Customization'}
                </Header>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}

export default IntroSecAnimated
