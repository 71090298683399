import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './occasionAnimated.css'
import { Grid, Header, Segment } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const userLang = navigator.language || navigator.userLanguage

gsap.registerPlugin(ScrollTrigger)

// const getComplementaryColor = (rgb) => {
//   const [r, g, b] = rgb.match(/\d+/g).map(Number);
//   return `rgb(${255 - r}, ${255 - g}, ${255 - b})`;
// };

const IntroSecAnimated = () => {
  const sectionsRef = useRef([])
  const sectionsRef1 = useRef([])
  useEffect(() => {
    gsap.to(sectionsRef.current, {
      xPercent: -200 * (sectionsRef.current.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: sectionsRef.current[0],
        pin: true,
        scrub: 1,
        snap: 1 / (sectionsRef.current.length - 1),
        end: () =>
          `+=${
            sectionsRef.current[0].offsetWidth *
            (sectionsRef.current.length - 1)
          }`
      }
    })

    gsap.to(sectionsRef1.current, {
      xPercent: -200 * (sectionsRef1.current.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: sectionsRef1.current[0],
        pin: true,
        scrub: 1,
        snap: 1 / (sectionsRef1.current.length - 1),
        end: () =>
          `+=${
            sectionsRef1.current[0].offsetWidth *
            (sectionsRef1.current.length - 1)
          }`
      }
    })
  }, [])

  return (
    <div>
      <div className='occasion-container'>
        <div
          className='occasion-section'
          ref={el => (sectionsRef.current[0] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/tennis_5d41863bed.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
        <div
          className='occasion-section'
          ref={el => (sectionsRef.current[1] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/couture_f2bfe95973.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
      </div>

      <div className='static-container'>
        <div
          className='half-height-section'
          style={{ backgroundColor: '#00BAB0', zIndex: 1000 }}
        >
          <Grid centered>
            <Grid.Row>
              <Header
                style={{
                  fontSize: 40,
                  color: 'black',
                  textAlign: 'center',
                  textShadow: '2px 2px #e4bf8f'
                }}
              >
                {userLang === 'zh-CN'
                  ? '让时尚循环起来'
                  : 'Keep fashion circular with styles'}
              </Header>
            </Grid.Row>
            <Grid.Row>
              <Link to='/clothing'>
                <Button
                  color='orange'
                  circular
                  size='big'
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 50,
                    paddingRight: 50,
                    backgroundColor: '#e4bf8f',
                    color: '#493d2b'
                  }}
                >
                  {userLang === 'zh-CN' ? '浏览' : 'Browse'}
                </Button>
              </Link>
            </Grid.Row>
          </Grid>
        </div>
      </div>

      <div className='occasion-container'>
        <div
          className='occasion-section'
          ref={el => (sectionsRef1.current[0] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638068/metalic_541fb1a11a.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
        <div
          className='occasion-section'
          ref={el => (sectionsRef1.current[1] = el)}
          style={{
            backgroundImage:
              "url('https://res.cloudinary.com/sheyou/image/upload/v1727638234/suit_ef6ed1de24.svg')"
          }}
        >
          <div className='full-height-section' style={{ padding: 30 }}></div>
        </div>
      </div>
    </div>
  )
}

export default IntroSecAnimated
