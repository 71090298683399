import React from 'react'
import {
  Menu,
  Button,
  Icon,
  Confirm,
  Popup,
  Label,
  Header,
  Search
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

import algoliasearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch'

import axios from 'axios'

const userLang = navigator.language || navigator.userLanguage
const searchClient = algoliasearch(
  'TA5GYUAPCR',
  '005f9c0c87592f1e4680023cd06179c0'
)

class Nav extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeItem: '',
      login: 'false',
      open: false,
      identifier: '',
      cart: [],
      bag: [],
      accessoryCart: [],
      accessoryBag: [],
      isAMember: false,
      windowWidth: 0
      // show:false
    }
    this.onHandleSignOut = this.onHandleSignOut.bind(this)
    this.handleItemClick = this.handleItemClick.bind(this)
    this.show = this.show.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleItemClickMember = this.handleItemClickMember.bind(this)
  }

  componentDidMount () {
    const self = this
    console.log(self)

    const menus = [
      { include: 'browse', activeItem: 'Browse' },
      { include: 'about', activeItem: 'About' },
      { include: 'merchant', activeItem: 'Merchants' },
      { include: 'contactUs', activeItem: 'Contact' },
      { include: 'mypage', activeItem: 'MyPage' },
      { include: 'Cart', activeItem: 'Cart' },
      { include: 'heart', activeItem: 'Heart' }
    ]
    var pathname = self.props.location.pathname
    menus.forEach(function (each) {
      if (pathname.search(each.include) !== -1) {
        self.setState({ activeItem: each.activeItem })
      }
    })

    this.props.history.listen((location, action) => {
      console.log(location, action)
      const path = location.pathname
      menus.forEach(function (each) {
        if (path.search(each.include) !== -1) {
          self.setState({ activeItem: each.activeItem })
        }
      })

      self.getUserInfo()
    })
    this.getUserInfo()
    // const cart=this.state.cart
    // const bag=this.state.bag
  }

  handleItemClickMember () {
    const isAMember = this.state.isAMember
    if (isAMember === true) {
      this.props.history.push('/mypage/membership')
    } else {
      this.props.history.push('/plans')
    }
  }

  getUserInfo () {
    const self = this
    const log = localStorage.getItem('login')
    const identifier = localStorage.getItem('identifier')

    const token = localStorage.getItem('token')

    // const now = timestamp.utc('YYYY-MM-DD')

    // console.log(now)

    // const activeItem=localStorage.getItem('menu')
    // if(activeItem!==undefined||activeItem!==null)
    // {self.setState({activeItem:activeItem})}

    self.setState({
      login: log,
      identifier: identifier
    })

    if (log === 'true') {
      axios('https://sheyou-backend.herokuapp.com/users/me', {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token
        }
      }).then(function (res) {
        if (res.status === 200) {
          var userInfo = [res.data]

          if (userInfo[0].cart === null) {
            userInfo[0].cart = []
          }
          if (userInfo[0].accessoryCart === null) {
            userInfo[0].accessoryCart = []
          }
          if (userInfo[0].accessoryBag === null) {
            userInfo[0].accessoryBag = []
          }
          if (userInfo[0].bag === null) {
            userInfo[0].bag = []
          }
          self.setState({
            userInfo: userInfo,
            userID: userInfo[0].id,
            cart: userInfo[0].cart,
            bag: userInfo[0].bag,
            accessoryCart: userInfo[0].accessoryCart,
            accessoryBag: userInfo[0].accessoryBag,
            membership: userInfo[0].membership
          })

          if (userInfo[0].membership !== null) {
            self.setState({
              isAMember: userInfo[0].membership.isAMember
            })
          }
          userInfo = JSON.stringify(userInfo)
          localStorage.setItem('userInfo', userInfo)
        } else {
          alert('Something went wrong, maybe log in again :(')
          localStorage.setItem('login', 'false')
        }
      })
    }
  }

  handleItemClick (target, data) {
    // const name=data.name
    const link = data.linkto
    console.log(target)
    this.setState({
      activeItem: data.name
    })
    if (link === '/clothing') {
      localStorage.setItem('filter', 'Clothing')
    }
    if (link === '/heart') {
      localStorage.setItem('filter', 'All')
    }
    const self = this

    if (link === '/merchantLog') {
      const merchantLogin = localStorage.getItem('merchantLogin')
      if (merchantLogin !== 'true') {
        self.props.history.push('/merchantsLog')
      } else {
        self.props.history.push('/merchantPage')
      }
    } else {
      self.props.history.push(link)
    }
    window.setTimeout(() => {
      if (self.state.loading === true) {
        window.location.reload(true)
      }
    }, 200)
  }

  onHandleSignOut (event) {
    event.preventDefault()
    const self = this
    self.setState({ login: false, open: false })
    const rememberMe = localStorage.getItem('rememberMe')
    if (rememberMe === 'true') {
      localStorage.setItem('login', 'false')
    } else {
      localStorage.setItem('login', 'false')
      localStorage.setItem('identifier', '')
      localStorage.setItem('password', '')
    }

    // alert('you have been signed out')
    self.props.history.push('/')
  }

  show () {
    const self = this
    self.setState({ open: true })
  }

  handleCancel () {
    const self = this
    self.setState({ open: false })
  }

  // handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render () {
    const activeItem = this.state.activeItem
    const log = this.state.login
    const identifier = this.state.identifier
    // const name=localStorage.getItem('identifier')

    const self = this
    const s = self.state

    var link = '/cart'
    const cartNum =
      s.cart.length +
      s.accessoryCart.length +
      s.bag.length +
      s.accessoryBag.length
    if (
      s.cart.length + s.accessoryCart.length === 0 &&
      s.bag.length + s.accessoryBag.length > 0
    ) {
      link = '/cart?rent=true'
    }
    // const bagNum = s.bag.length + s.accessoryBag.length

    var browse = 'Browse'
    var about = 'About'
    var merchants = 'Merchants'
    var contact = 'Contact'

    if (userLang === 'zh-CN') {
      browse = '浏览'
      about = '关于我们'
      merchants = '商户'
      contact = '联系'
    }

    return (
      <>
        <Menu
          inverted
          color='teal'
          size='huge'
          borderless
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingBottom: 10,
            paddingTop: 10
          }}
        >
          <Menu.Item>
            <Link to='/'>
              <Header
                as='h1'
                inverted
                linkto='/'
                name='Home'
                active={activeItem === 'Home'}
                style={{ paddingRight: 10 }}
                // style={{ position: 'relative', top: 13, left: 5 }}
              >
                SHEYOU
                <Label style={{ backgroundColor: '#e4bf8f' }}>BETA</Label>
              </Header>
            </Link>
          </Menu.Item>

          <>
            <Menu.Item
              name={browse}
              linkto='/clothing'
              active={activeItem === 'Clothing'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name={about}
              linkto='/about'
              active={activeItem === 'About'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              linkto='/merchantsLog'
              name={merchants}
              active={activeItem === 'Merchants'}
              onClick={this.handleItemClick}
            />

            <Menu.Item
              name={contact}
              linkto='/contactUs'
              active={activeItem === 'Contact'}
              onClick={this.handleItemClick}
            />
          </>

          <Menu.Menu position='right'>
            <Menu.Item>
              {/* <Search
                placeholder='Search...'
              /> */}

              <InstantSearch indexName='bestbuy' searchClient={searchClient}>
                <Search placeholder='Search...' />
                {/* <Hits /> */}
              </InstantSearch>
            </Menu.Item>
          </Menu.Menu>

          {log === 'true' && (
            <Menu.Menu position='right' icon='labeled'>
              <Popup
                trigger={
                  <Menu.Item
                    name='MyPage'
                    linkto='/mypage'
                    active={activeItem === 'MyPage'}
                    onClick={this.handleItemClick}
                  >
                    <Icon name='user' inverted />

                    {/* My */}
                  </Menu.Item>
                }
                content={'Hi, ' + identifier}
                style={{
                  borderRadius: 12,
                  opacity: 0.9,
                  padding: '1.5em',
                  color: 'orange'
                }}
              />

              {/* <Menu.Item
                name='Rent'
                linkto='/memberbox'
                active={activeItem === 'Rent'}
                onClick={this.handleItemClick}
              >
                <Icon name='shopping bag' inverted>
                  <Label floating circular>
                    {bagNum}
                  </Label>
                </Icon>
               
              </Menu.Item> */}

              <Menu.Item
                name='Cart'
                linkto={link}
                active={activeItem === 'Cart'}
                onClick={this.handleItemClick}
              >
                <Icon name='shopping cart' inverted>
                  <Label floating circular>
                    {cartNum}
                  </Label>
                </Icon>

                {/* heart */}
              </Menu.Item>

              <Menu.Item
                name='Heart'
                linkto='/heart'
                active={activeItem === 'Heart'}
                onClick={this.handleItemClick}
              >
                <Icon name='heart' inverted />
                {/* heart */}
              </Menu.Item>

              <Menu.Item
                name='Chat'
                linkto='/message'
                active={activeItem === 'Chat'}
                onClick={this.handleItemClick}
              >
                <Icon name='chat' inverted />
                {/* heart */}
              </Menu.Item>

              <Menu.Item>
                {/* <Link to="/"> */}
                <>
                  <Button size='huge' inverted onClick={this.show}>
                    {userLang === 'zh-CN' && '退出'}
                    {userLang !== 'zh-CN' && 'Log Out'}
                  </Button>
                  {userLang === 'zh-CN' && (
                    <Confirm
                      open={this.state.open}
                      cancelButton='算了'
                      confirmButton='确认'
                      content='您确定要退出？'
                      onCancel={this.handleCancel}
                      onConfirm={this.onHandleSignOut}
                    />
                  )}
                  {userLang !== 'zh-CN' && (
                    <Confirm
                      open={this.state.open}
                      cancelButton='Never mind'
                      confirmButton='Yes'
                      content='Are you sure you wnt to log out?'
                      onCancel={this.handleCancel}
                      onConfirm={this.onHandleSignOut}
                    />
                  )}
                </>

                {/* </Link> */}
              </Menu.Item>
            </Menu.Menu>
          )}

          {log !== 'true' && (
            <Menu.Menu position='right'>
              <Menu.Item>
                <Link to='/signin'>
                  <Button size='huge' inverted>
                    {userLang === 'zh-CN' && '登录 / 注册'}
                    {userLang !== 'zh-CN' && 'Sign In/ Sign Up'}
                  </Button>
                </Link>
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>
      </>
    )
  }
}

export default withRouter(Nav)
